import './App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Home from './components/home';
import Members from './components/members';
import Register from './components/register';
import { Routes, Route, Link } from 'react-router-dom';
import ViewPhotos from './components/viewphotos';

function App() {

  return (
    <div className="App">
      <header className="App-header">
      <Container>
    <Row className="align-items-center"><Col md="4">Holden Professional Firefighters<br/>Local 4557</Col><Col md="4"><div style={{padding:'10px', height:'150px', margin:'10px'}}><img src='ff.png' width={'100px'} height={'100px'} alt='Holden professional firefighters local 4557 logo v112323'/></div></Col>
    <Col md="4">Holden<br/>Massachusetts</Col></Row>
   </Container>
   </header>
   <div>

   
   </div>
   <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/members" element={<Members />} />
        <Route path="/viewphotos" element={<ViewPhotos />} />
        <Route path="/register" element={<Register />} />
        </Routes>
    </div>

  );
}

export default App;
